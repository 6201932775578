@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

:root {
    --primary-color: #2816b2;
    --secondary-color: #382fd8;
    --success-color: #1EBB4D;
    --background-color: #EEEEEE;
    --shadown-color: #a19f9f;
    --red: #ed1d46;
    --black: #1e1e1e;
}


:root.l2p {
    --primary-color: #2816b2;
    --secondary-color: #382fd8;
    --success-color: #1EBB4D;

    --background-color: #EEEEEE;
    --shadown-color: #a19f9f;
    --red: #ed1d46;
    --black: #1e1e1e;
  }


:root.cta {
    --primary-color: #574ae2;
    --secondary-color: #382fd8;
    --success-color: #19e098;

    --background-color: #EEEEEE;
    --shadown-color: #a19f9f;
    --red: #ed1d46;
    --black: #1e1e1e;
}
  


body {

    font-family: 'Poppins', sans-serif;
    margin: 0;
    background-color: white;
}

body, html{
    margin: 0;
}

h1, h2, h3, h4, h5, h6 ,p{
    color: var(--primary-color);
    margin: 0;
}

p, label{
    color: var(--black);
}

.logo-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-img{
    width: 8em;
}

.error{
    color: var(--red);
}

.page-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.back-content{
    cursor: pointer;
}

.page-content{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    min-height: calc(100vh - 5em)
}

app-header{
    width: 100%;
}

app-error{
    width: 100%;
    height: 100vh;
}

.page-data{
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;
    padding: 20px;

    div{
        width: 100%;
    }
}

.form-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        width: 80%;
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-group{
        margin-bottom: 10px;
    }

    label {
        font-weight: 700;
    }

    form{
        width: 80%;
    }
}

.form-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-btns{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btn-form {
    background-color: var(--primary-color)!important;
    color: white !important;
    width: 100%;
    padding: 8px;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.btn-reset{
    width: 100%;
    border-color: var(--shadown-color);


    &:hover{
        background-color: var(--shadown-color);
        color: white;
        cursor: pointer;
    }
}

@media only screen and (min-width: 600px) {

    body {
        background-color: var(--background-color);
    }
    .page-content{
        padding: 20px;
    }

    .page-data {
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-shadow: 1px 2px 5px var(--shadown-color);
        padding: 30px 10px;
        width: 70vw;
        max-width: 500px;
    }
}



